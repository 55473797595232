import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { HubEventsHeader, Footer, SEO, FutureEvents, PastEvents, ProtectedRoute } from 'components';
// import { LocalContext, FirebaseContext } from 'context';
import { pfizer } from 'styles';
// import { HubVector } from 'assets/svgs';
import { graphql } from 'gatsby';
// import { motion } from 'framer-motion';
import { LocalContext } from 'context';

const EventsHub = ({ data }) => {
  const { edges: events } = data.allMarkdownRemark;
  const colors = pfizer;

  const futureEvents = events.filter(
    ({ node }) => node.frontmatter.type === 'future' && !node.frontmatter.hidden
  );
  const pastEvents = events.filter(
    ({ node }) => node.frontmatter.type === 'past' && !node.frontmatter.hidden
  );

  return (
    <ProtectedRoute>
      <SEO pageSpecificTitle="Events" />
      <HubEventsHeader colors={colors} />
      {/* <Container>
        <AboutSection colors={colors}>
          <div>
            <h2>Benefits of Attending Pfizer Virtual EVENTS</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis neque vitae
              risus fringilla, at faucibus justo elementum. Orci varius natoque penatibus et magnis
              dis parturient montes, nascetur ridiculus mus. Fusce semper arcu a sem sodales.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis neque vitae
              risus fringilla, at faucibus justo elementum.
            </p>
          </div>
          <div>
            <HubVector />
          </div>
        </AboutSection>
      </Container> */}
      {futureEvents.length > 0 && (
        <Container bg="rgba(196, 196, 196, 0.2);">
          <Section colors={colors}>
            <h2>Future Events</h2>
            <FutureEvents colors={colors} />
          </Section>
        </Container>
      )}
      {pastEvents.length > 0 && (
        <Container bg="rgba(196, 196, 196, 0.2);">
          <Section colors={colors}>
            <h2>Past Events</h2>
            <PastEvents colors={colors} />
          </Section>
        </Container>
      )}

      {/* <Container>
        <Section colors={colors}>
          <h2>Frequently Asked Questions</h2>
          <p>View some of our frequently asked questions below</p>
          <HubFaq colors={colors} />
        </Section>
      </Container> */}
      <Footer />
    </ProtectedRoute>
  );
};

const Section = styled.section`
  grid-column: 1/7;
  margin: 3rem 0;
  h2 {
    color: #00004e;
    font-size: 1.5rem;
    margin-bottom: 1em;
    text-align: center;
    width: 100%;
  }
  p {
    color: #3c3c3c;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5em;
    margin: 0 auto 3rem;
    max-width: 100%;
    text-align: center;
    width: 800px;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    p {
      margin-bottom: 4rem;
    }
  }
`;

const Container = styled.section`
  align-items: flex-start;
  background-color: ${({ bg }) => bg};
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 3rem 1rem;
  @media (min-width: 768px) {
    padding: 3rem 1.25rem;
  }
  @media (min-width: 1150px) {
    padding: 3rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const query = graphql`
  query EventsQuery {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(events)/" }
        frontmatter: { startTime: { ne: null } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            type
            name
            eid
            hidden
            dates
            description
            startTime
            endTime
            thumb {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default EventsHub;
